
import MotifClass from '@/types/class'
import Student from '@/types/student'
import Guardian from '@/types/guardian'
import { defineComponent } from 'vue'
import utils from '@/utils/utils'
import financialFormatMixin from '@/utils/financialFormatMixin'
import CancelEnrollment from '@/components/CancelEnrollment.vue'

export default defineComponent({
  components: { CancelEnrollment },
  mixins: [financialFormatMixin],
  data () {
    return {
      name: '',
      date: '',
      selectedClass: { courseName: '', dates: [{ date: '' }] } as MotifClass,
      selectedStudents: [] as Student[]
    }
  },
  mounted () {
    if (!this.$store.getters.selectedClass) {
      return this.$router.replace({ name: 'home' })
    }
    if (this.$store.getters.selectedStudents.length < 1) {
      return this.$router.replace({ name: 'enroll_students' })
    }
    this.selectedClass = this.$store.getters.selectedClass
    this.selectedStudents = this.$store.getters.selectedStudents
  },
  computed: {
    guardian (): Guardian {
      return this.$store.getters.guardian
    },
    studentNames (): string[] {
      return this.selectedStudents.map((student: Student) => { return `${student.firstName} ${student.lastName}` })
    },
    startDate (): string {
      return utils.dateStringToHumanReadable(this.selectedClass.dates[0].date)
    },
    stopDate (): string {
      const dates = this.selectedClass.dates
      return utils.dateStringToHumanReadable(dates[dates.length - 1].date)
    },
    classLengthHours (): number {
      const milliseconds = (new Date(this.selectedClass.stopTime)).getTime() - (new Date(this.selectedClass.startTime)).getTime()
      return milliseconds / 1000 / 60 / 60
    },
    classTotalHours (): number {
      return this.classLengthHours * this.selectedClass.dates.length
    },
    today (): string {
      return utils.dateStringToDateInputValue(new Date().toString())
    },
    guardianName (): string {
      return `${this.guardian.firstName} ${this.guardian.lastName}`
    }
  },
  methods: {
    async enroll () {
      const studentIds: number[] = this.selectedStudents.map((student: Student) => { return student.id })
      const response = await this.$api.enroll(this.selectedClass.id, studentIds, this.name, this.date)
      // continue to charge review
      if (response.charge) {
        this.$store.commit('selectStudents', { students: [] })
        this.$store.commit('selectClass', { class: null })
        this.$router.replace({ name: 'home' })
        return this.$router.push({ name: 'charges' })
      }
    }
  }
})

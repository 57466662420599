<template>
  <div class="container mt-3">
    <h4 class="mb-3">Enrollment Agreement</h4>
    <p>To enroll {{ studentNames.join(', ') }} in {{ selectedClass.courseName }}, please read and agree to the following:</p>
    <pre class="pre-wrap bg-light p-3" style="max-height: 70vh;"><strong>CHILD CARE AGREEMENT</strong>

Motif Class LLC
2700 Caples Ave #2525 Vancouver, WA 98661
971-373-5303

This agreement is between Motif Class LLC (hereinafter referred to as: Motif Class) and:

Parent Name/Legal Guardian: {{ guardian.firstName }} {{ guardian.lastName }}
Physical Address: {{ guardian.addressStreet }}
City: {{ guardian.addressCity }}
State: {{ guardian.addressState }}
Zip: {{ guardian.addressZip }}
Telephone: {{ guardian.phoneNumber }}
Student Name(s): {{ studentNames.join(', ') }}

<strong>PROGRAM REGISTRATION</strong>

The above named parent or legal guardian (hereinafter referred to as: The Parent) agrees to enroll the above named student (hereinafter referred to as: The Student) into the following Motif Class program:

Course Name: {{ selectedClass.courseName }}
Location: {{ selectedClass.location }}
Start Date: {{ startDate }}
Completion Date: {{ stopDate }}
Program Consists of: {{ selectedClass.dates.length }} session(s) x {{ classLengthHours }} hours per session = {{ classTotalHours }} total hour(s).
Registration Fee: {{ financialFormat(selectedClass.price) }} x {{ selectedStudents.length }} student(s) = {{ financialFormat(selectedClass.price * selectedStudents.length) }}

The Parent agrees that the payment of the program will be satisfied immediately upon registration by means of online payment.

<strong>CURRENT TERMS OF PARTICIPATION</strong>

Motif Class requires explicit agreement to the following terms in order to participate in any of its programs (such programs include, but are not limited to: after-school care, enrichment activities, classes, courses, summer camps, events, correspondence, and any group meeting or educational session organized by Motif Class regardless of location).
I, The Parent agree to the following terms:
* I am legally authorized to enroll The Student into the above named Motif Class program.
* I agree to pay in full all tuition charges associated with the above named Motif Class program.
* I understand that The Student’s continued participation in any Motif Class program is dependent upon The Student's ability to meet behavior expectations determined in good faith by Motif Class staff, and in the event expectations continue to be unmet, Motif Class may choose to end The Student's participation in any Motif Class program with no obligation to refund tuition or otherwise provide compensation.
* I understand that classes and courses will not be prorated in regard to the number of sessions attended by The Student, rather tuition for each class or course is a fixed up-front fee.
* I understand that all refunds and/or credits are issued at the sole discretion of Motif Class and are not guaranteed.
* I understand that The Student’s participation in any Motif Class program includes risk of injury that may range in severity from minor to disabling or even to death. Although serious injuries are uncommon in supervised programs, it is impossible to eliminate the risk.
* I give permission that The Student may be given first aid/emergency treatment by Motif Class staff.
* When I cannot be contacted, I authorize and consent to medical, surgical and hospital care, treatment and procedures to be performed for The Student by a licensed physician, health care provider, hospital or aid car attendant when deemed necessary or advisable by the physician or aid care attendant to safeguard The Student’s health. I waive my right of informed consent to such treatment. I also give my permission for The Student to be transported by ambulance or aid car to an emergency center for treatment.
* I understand and agree that Motif Class, their employees, members, officers and agents will not be liable for personal injuries and/or property damage resulting from The Student’s participation in any Motif Class program.
* I agree to release, hold harmless and indemnify Motif Class, their employees, members, officers and agents, from any loss, cost, damage and/or expense of any nature, including all attorneys' fees and costs which I or The Student may have resulting, either directly or indirectly, from The Student’s participation in any Motif Class program.
* I give permission and consent for The Student to participate in all Motif Class activities, and do forever release Motif Class and its teachers, members, staff, volunteers and agents from any and all actions, all known and unknown personal injuries or property damage of The Student arising out of said activities, and also all claims or right of action for damages which The Student has or hereafter may acquire.

<strong>PHOTO/VIDEO RELEASE</strong>

* Motif Class is hereby granted permission to take photographs and/or video of The Student to use in print or electronic publication. For safety, I understand that Motif Class will not use The Student's name or information on the Internet or in any other publication. I hereby give Motif Class exclusive rights to all media. This includes all print, Internet and any other electronic media, advertising, posters, and other promotional materials that may be created in the future. This photo waiver applies to all classes, care sessions, enrichment activities, and events that are associated with Motif Class.
* By signing this agreement, I acknowledge that I have read and understand this document and accept the risk and responsibility of participation in any Motif Class program.
Certification:
I, The Parent, certify that I read, understand, and fully agree to the terms of this agreement.</pre>
    <h5>Enter your full name and today's date to indicate that you have read and agree to the above terms.</h5>
    <form @submit.prevent="enroll()">
    <div class="row row-cols-lg-auto g-3 align-items-end">
      <div>
        <div class="mb-3">
          <label for="name">Your name</label>
          <input v-model="name" class="form-control" type="text" name="name" id="name" required :pattern="guardianName">
        </div>
      </div>
      <div>
        <div class="mb-3">
          <label for="name">Today's date</label>
          <input v-model="date" class="form-control" type="date" name="date" id="date" required :min="today" :max="today">
        </div>
      </div>
      <div>
        <button class="btn btn-success mb-3" type="submit">Agree and continue</button>
      </div>
      <div>
        <cancel-enrollment />
      </div>
    </div>
    </form>
  </div>
</template>

<script lang="ts">
import MotifClass from '@/types/class'
import Student from '@/types/student'
import Guardian from '@/types/guardian'
import { defineComponent } from 'vue'
import utils from '@/utils/utils'
import financialFormatMixin from '@/utils/financialFormatMixin'
import CancelEnrollment from '@/components/CancelEnrollment.vue'

export default defineComponent({
  components: { CancelEnrollment },
  mixins: [financialFormatMixin],
  data () {
    return {
      name: '',
      date: '',
      selectedClass: { courseName: '', dates: [{ date: '' }] } as MotifClass,
      selectedStudents: [] as Student[]
    }
  },
  mounted () {
    if (!this.$store.getters.selectedClass) {
      return this.$router.replace({ name: 'home' })
    }
    if (this.$store.getters.selectedStudents.length < 1) {
      return this.$router.replace({ name: 'enroll_students' })
    }
    this.selectedClass = this.$store.getters.selectedClass
    this.selectedStudents = this.$store.getters.selectedStudents
  },
  computed: {
    guardian (): Guardian {
      return this.$store.getters.guardian
    },
    studentNames (): string[] {
      return this.selectedStudents.map((student: Student) => { return `${student.firstName} ${student.lastName}` })
    },
    startDate (): string {
      return utils.dateStringToHumanReadable(this.selectedClass.dates[0].date)
    },
    stopDate (): string {
      const dates = this.selectedClass.dates
      return utils.dateStringToHumanReadable(dates[dates.length - 1].date)
    },
    classLengthHours (): number {
      const milliseconds = (new Date(this.selectedClass.stopTime)).getTime() - (new Date(this.selectedClass.startTime)).getTime()
      return milliseconds / 1000 / 60 / 60
    },
    classTotalHours (): number {
      return this.classLengthHours * this.selectedClass.dates.length
    },
    today (): string {
      return utils.dateStringToDateInputValue(new Date().toString())
    },
    guardianName (): string {
      return `${this.guardian.firstName} ${this.guardian.lastName}`
    }
  },
  methods: {
    async enroll () {
      const studentIds: number[] = this.selectedStudents.map((student: Student) => { return student.id })
      const response = await this.$api.enroll(this.selectedClass.id, studentIds, this.name, this.date)
      // continue to charge review
      if (response.charge) {
        this.$store.commit('selectStudents', { students: [] })
        this.$store.commit('selectClass', { class: null })
        this.$router.replace({ name: 'home' })
        return this.$router.push({ name: 'charges' })
      }
    }
  }
})
</script>

<style>
.pre-wrap {
  white-space: pre-wrap;
}
</style>
